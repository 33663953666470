<template>
  <div>
    <div class="col-12">
      <h3>Firma</h3>
    </div>

    <div v-if="showSuccess" class="alert alert-success" role="alert">
      Klient został dodany
    </div>
    <div v-if="showFail" class="alert alert-danger" role="alert">
      Nie ma nazwy klienta.
    </div>

    <form class="add-form bg-light border" @submit.prevent>
      <input
        v-model="name"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Nazwa firmy"
      />
      <input
        v-model="nip"
        type="text"
        class="form-control mb-1"
        placeholder="Nip"
      />
      <input
        v-model="contactPerson"
        type="text"
        class="form-control mb-1"
        placeholder="Osoba kontaktowa"
      />
      <input
        v-model="email"
        type="email"
        class="form-control mb-1"
        placeholder="Email"
      />

      <input
        v-model="phoneNumber"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Numer Telefonu"
      />
      <input
        v-model="address"
        type="text"
        class="form-control mb-1"
        placeholder="Adres"
      />
      <textarea
        v-model="notice"
        type="text"
        class="form-control mb-1"
        placeholder="Uwagi"
      />

      <button
        style="width: 100%; height: 65px"
        class="btn btn-info btn-sm mt-1"
        @click="addContractor()"
      >
        Dodaj firmę
      </button>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      nip: "",
      contactPerson: "",
      email: "",
      phoneNumber: "",
      address: "",
      notice: "",
      showSuccess: false,
      showFail: false,
    };
  },
  methods: {
    addContractor() {
      if (this.name == "") {
        this.showFail = true;
        setTimeout(() => {
          this.showFail = false;
        }, 2000);
      } else {
        this.$store
          .dispatch("createContractor", {
            name: this.name,
            nip: this.nip,
            contactPerson: this.contactPerson,
            email: this.email,
            phoneNumber: this.phoneNumber,
            address: this.address,
            notice: this.notice,
            type: "company",
          })
          .then((this.showSuccess = true));

        (this.name = ""),
          (this.nip = ""),
          (this.contactPerson = ""),
          (this.email = ""),
          (this.phoneNumber = ""),
          (this.address = ""),
          (this.notice = "");
      }
    },
  },
};
</script>
<style>
.add-form {
  width: 100%;
  max-width: 33vw;
  padding: 15px;
  margin: 0 auto;
}
</style>